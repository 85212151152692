/* Copyright (C) gotoviar - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by gotoviar <info@gotoviar.com>, December 2022
 */

class GTVRModule {

    static isXrSupported = false;

    constructor(options) {

        var style = document.createElement('style');
        style.innerHTML = '.cssXRButton {background-color: rgba(100,100,100,0.8); color: #000; position: absolute; left: 50%; top: 25%; z-index: 1001; font-size: 19pt; transform: translate(-50%, -50%);}';
        document.getElementsByTagName('head')[0].appendChild(style);

        // Application options and defaults
        if (!options) { options = {}; }

        if (!'project_name' in options)
            throw 'Project Name can\'t be null';

        this.options = {
            wasm_url: options ? options.wasm_url : document.location.href + 'wasm',
            wasm_name: options ? options.wasm_name : 'webxr_app',
            project_url: options ? options.project_url : document.location.href,
            project_name: options.project_name,
            canvas: options ? options.canvas : null
        };

        window.shutdownGTVR = (function () {
            document.body.style.overflow = window.Module.gtvr.scrollbars;
            delete window.Module;
            delete window.shutdownGTVR;
        });
    }

    createCanvas() {
        let canvas = document.createElement('canvas');
        canvas.id = 'gvrcanvas';
        //canvas.style = {"backgroundColor": "rgba(0, 0, 0, 1.0)", "zIndex": "1000", "position": "fixed", "top": 0, "bottom": 0, "overflow": "hidden"};
        canvas.style.backgroundColor = "rgba(0, 0, 0, 1.0)"; 
        canvas.style.zIndex = "1000";
        canvas.style.position = "fixed";
        canvas.style.top = "0";
        canvas.style.bottom = "0";
        canvas.style.overflow = "hidden";
        document.body.appendChild(canvas);
        return canvas;
    }

    hideScrollBars() {
        window.scrollTo(0, 0);
        this.scrollbars = document.body.style.overflow;
        document.body.style.overflow = "hidden";
    }

    run()
    {
        navigator.permissions.query({ name: "microphone" }).then((result) => {
            if(result.state == "granted"){
                this.imp_run();
            }
            else {
                navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) =>
                {
                    window.location.reload();
                    //alert('Please click again the button.');
                })
                .catch((err) => {
                });
            }
        });


        /*try {
            let stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            this.run();
        } catch(err) {
            console.log(err);
        }*/
    }

    imp_run() {
        var xhr_pd = new XMLHttpRequest();

        xhr_pd.onload = function (e) {
            if (xhr_pd.readyState === 4) {
                if (xhr_pd.status === 200) {
                    window.Module =
                    {
                        gtvr: this,
                        wasmBinary: xhr_pd.response,
                        preRun: (function () {
                                window.ENV.PROJECT_PATH = this.options.project_url + '/' + this.options.project_name;
                        }).bind(this),
                        canvas: (function () {
                            var canvas = typeof this.options.canvas === "string" ? document.getElementById(this.options.canvas) : this.createCanvas();
                            canvas.width = window.innerWidth;
                            canvas.height = window.innerHeight;
                            canvas.addEventListener("webglcontextlost", function (e) { alert('WebGL context lost. You will need to reload the page.'); e.preventDefault(); }, false);
                            canvas.focus();
                            return canvas;
                        }).bind(this)()
                    };

                    this.hideScrollBars();

                    const script = document.createElement("script");
                    script.src = this.options.wasm_url + this.options.wasm_name + '.js';
                    script.async = true;
                    document.body.appendChild(script);

                    window.addEventListener('resize', function () {
                        window.Module.canvas.width = window.innerWidth;
                        window.Module.canvas.height = window.innerHeight;
                        window.Module.canvas.dispatchEvent(new Event('resize'));
                    }, false);

                    window.Module.canvas.dispatchEvent(new Event('resize'));
                }
            }
        }.bind(this);

        xhr_pd.onerror = function (e) { console.error(xhr_pd.statusText); };

        xhr_pd.open("GET", this.options.wasm_url + this.options.wasm_name + '.wasm', true);
        xhr_pd.responseType = 'arraybuffer';
        xhr_pd.send(null);
    }
}

export default GTVRModule;