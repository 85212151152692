import React, {Component} from "react";
import {Link} from 'react-router-dom';

class PortfolioMasonry extends Component {
    render() {
        const {column, list, listOpts} = this.props;
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div className="im_portfolio">
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to="/portfolio-details">
                                        {value.image}
                                    </Link>
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to="/portfolio-details">{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to="/portfolio-details"></Link>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}

export default PortfolioMasonry;